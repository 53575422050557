import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Row, Col, Form, Button, Card } from "react-bootstrap"
import firebase from "gatsby-plugin-firebase"
import { slugify } from "../helpers"
import Commitments from "./commitments"
import { GoogleData } from "../components/dataSource"

const EmailVerified = ({ handleShow, setModalContent, user, location }) => {
  const [fbUser, setFbUser] = useState({})
  const [fbOrg, setFbOrg] = useState({})

  // const orgsRef = firebase.database().ref("/orgs/");
  const [formValues, setFormValues] = useState({
    name: "",
    approved: false,
    role: "",
    company: "",
    companySlug: "",
    email: user.email,
  })

  //collect and sort companies
  const raw = GoogleData().map(item => {
    return item.company
  })

  const uniqueCompanies = [...new Set(raw)]

  const handleInputChange = e => {
    const { name, value } = e.target
    let fbValue = value

    //if company,
    if (name === "company") {
      fbValue = slugify(value)

      setFbOrg(prevState => ({
        ...prevState,
        ["company"]: fbValue,
        ["companyName"]: value,
      }))
    } else if (name === "agencyType") {
      setFbOrg(prevState => ({
        ...prevState,
        ["agencyType"]: value,
      }))
      return
    }

    setFormValues({
      ...formValues,
      [name]: fbValue,
    })
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    if (formValues.company === "choose" || formValues.company === "") {
      setModalContent({
        title: "Whoops.",
        content: "Please select your company from the list.",
      })
      handleShow()
      return
    }

    firebase
      .database()
      .ref("/users/" + user.uid)
      .set(formValues)
    firebase
      .database()
      .ref("/orgs/" + fbOrg.company)
      .update({ companyName: fbOrg.companyName, agencyType: fbOrg.agencyType })

    // handleShow()

    //message 1
    const dataToUser = {
      fromName: "POCAM Admin",
      fromEmail: "wearepocam@gmail.com",
      toEmail: formValues.email,
      subject: "The Call for Equity Progress Tracker Access",
      message: `
        Hi ${formValues.name},

        Thank you for registering for The Call for Equity Progress Tracker. Someone from POCAM will be in touch shortly to approve your access. 

        We appreciate your help in keeping ${fbOrg.companyName} and our industry accountable.

        If you have any questions, please respond to this thread.

        Warm regards,

        The POCAM team
      `,
    }
    sendEmail(dataToUser)

    //message 2
    const dataToAdmin = {
      fromName: "POCAM Admin",
      fromEmail: "noreply@wearepocam.ca",
      toEmail: "wearepocam@gmail.com",
      subject: "The Call for Equity Progress Tracker Access",
      message: `
        Hi,

        ${formValues.name} has registered for The Call for Equity Progress Tracker on behalf of ${fbOrg.companyName}.

        Please review thier request at http://wearepocam.ca/admin/

        Thank you
      `,
    }
    sendEmail(dataToAdmin)
  }

  const sendEmail = async (data, e) => {
    try {
      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        //not 200 response
        return
      }
      //all OK
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Success`);
    } catch (e) {
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Fail`);
      //error
    }
  }

  useEffect(() => {
    firebase
      .database()
      .ref("/users/" + user.uid)
      .on("value", snapshot => {
        const val = snapshot.val()
        setFbUser(val)
      })
  }, [])

  return (
    <>
      {fbUser && fbUser.approved ? (
        <Commitments
          location={location}
          handleShow={handleShow}
          setModalContent={setModalContent}
          uid={user.uid}
          fbUser={fbUser}
        />
      ) : (
        <Row>
          {!fbUser ? (
            <>
              <Col md="6">
                <h1>Welcome to POCAM's Call for Equity Tracker</h1>
                <h2>First time here?</h2>
                <p className="lead">
                  Answering the Call for Equity is POCAM’s Progress Reporting
                  Tool for equity-committed advertising/marketing organizations.
                </p>
                <p>Please follow these steps to register.</p>
                <ol>
                  <li>
                    Check whether your organization has committed to{" "}
                    <Link to="/call-for-equity/">The Call for Equity</Link>
                    <br />
                    If not, please 
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfPtVY0Ew6Cgyhzriv4Op4tnWiZ8B9K6ZHLyDjLfpuOYAqZHA/viewform"
                      target="_blank"
                      rel="noreferrer"
                    >
                      sign the commitment
                    </a>
                    .
                    <br />
                    You must be authorized to sign on behalf your organization.
                    If you’re not, ask someone in your leadership to sign.
                  </li>
                  <li>
                    If your organization has already committed, report on your
                    progress by filling out and submitting the form on this
                    page.
                  </li>
                  <li>
                    POCAM will verify your details and you'll receive an email
                    telling you how to update your organization’s progress.
                  </li>
                </ol>
              </Col>
              <Col md="6">
                <Card>
                  <Card.Body>
                    <Form onSubmit={handleFormSubmit}>
                      <Form.Group controlId="formName">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control
                          required
                          name="name"
                          type="text"
                          placeholder="Enter your name"
                          onChange={handleInputChange}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formRole">
                        <Form.Label>Your Role</Form.Label>
                        <Form.Control
                          required
                          name="role"
                          type="text"
                          placeholder="Enter your name"
                          onChange={handleInputChange}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formRole">
                        <Form.Label>LinkedIn Profile URL (i.e.)</Form.Label>
                        <Form.Control
                          name="linkedin"
                          type="url"
                          required
                          placeholder="http://linkedin.com/profile"
                          onChange={handleInputChange}
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>

                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Label>Please select your company</Form.Label>
                        <Form.Control
                          name="company"
                          as="select"
                          size="sm"
                          custom
                          onChange={handleInputChange}
                          defaultValue="Choose..."
                        >
                          <option>Choose...</option>
                          {uniqueCompanies.map((company, index) => (
                            <option key={index} value={company}>
                              {company}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          If your organization isn't listed, please sign the{" "}
                          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfPtVY0Ew6Cgyhzriv4Op4tnWiZ8B9K6ZHLyDjLfpuOYAqZHA/viewform">Call for Equity</a>
                        </Form.Text>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Is this an agency or client?</Form.Label>

                        <Form.Check
                          custom
                          name="agencyType"
                          value="agency"
                          type="radio"
                          label="Agency"
                          // checked="true"
                          id="agency"
                          onChange={handleInputChange}
                          required
                          // checked={ orgResponses["agencyType"] === "agency" }
                        />
                        <Form.Check
                          custom
                          name="agencyType"
                          value="client"
                          type="radio"
                          label="Client"
                          id="client"
                          onChange={handleInputChange}
                          // checked={ orgResponses["agencyType"] === "client" }
                        />
                      </Form.Group>

                      <Button type="submit">Submit</Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </>
          ) : (
            <Col md={{ span: 6, offset: 3 }}>
              <Card>
                <Card.Body>
                  <h1>
                    Thanks for registering and for your commitment to justice.
                  </h1>
                  <p className="lead">
                    Watch your inbox for a POCAM email. We’re looking forward to
                    celebrating the progress your organization has made.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      )}
    </>
  )
}

export default EmailVerified
