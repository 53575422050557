import React, { useState, useEffect } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"

const CompleteFields = ({
  commitmentName,
  completed,
  orgResponses,
  handleChange,
  setOrgResponses,
}) => {
  const commitmenCountName = commitmentName + "-link-count"

  const handleAddLink = e => {
    setOrgResponses(prevState => ({
      ...prevState,
      [commitmenCountName]: orgResponses[commitmenCountName] + 1,
    }))
  }

  const handleRemoveLink = () => {
    setOrgResponses(prevState => ({
      ...prevState,
      [commitmenCountName]: orgResponses[commitmenCountName] - 1,
    }))
  }

  //if no commitmentLinkCount, set count to 1
  useEffect(() => {
    const commitmentCount = parseInt(orgResponses[commitmenCountName]) || 0

    if (commitmentCount < 1) {
      setOrgResponses(prevState => ({
        ...prevState,
        [commitmenCountName]: 1,
      }))
    }
  }, [])

  return (
    <>
      {[...Array(orgResponses[commitmenCountName])].map((e, i) => {
        let labelName = `${commitmentName}-link-${i}-label`
        let urlName = `${commitmentName}-link-${i}-url`

        //handle older data
        if (i === 0 && orgResponses[commitmentName + "-note"]) {
          labelName = commitmentName + "-label"
          urlName = commitmentName + "-note"
        }

        return (
          <Row key={i}>
            <Col xs="12" sm="6">
              <Form.Control
                className="mt-2 mb-0"
                type="text"
                name={labelName}
                placeholder="Link Title"
                onChange={handleChange}
                disabled={completed !== "completed"}
                defaultValue={orgResponses[labelName]}
                required
              ></Form.Control>
              <p className="text-secondary mb-1">
                <small>Enter a title for your public link</small>
              </p>
            </Col>
            <Col>
              <Form.Control
                className="mt-2 mb-1"
                type="url"
                name={urlName}
                placeholder="Link to publicly available completion or notes."
                onChange={handleChange}
                disabled={completed !== "completed"}
                defaultValue={orgResponses[urlName]}
                required
              ></Form.Control>
              <p className="text-secondary mb-3">
                <small>Enter the url for your public link</small>
              </p>
            </Col>
          </Row>
        )
      })}
      <Button
        disabled={orgResponses[commitmenCountName] > 2}
        size="sm"
        onClick={handleAddLink}
        className="mr-2"
      >
        Add Another Link
      </Button>
      <Button
        disabled={orgResponses[commitmenCountName] === 1}
        variant="secondary"
        size="sm"
        onClick={handleRemoveLink}
      >
        Remove Link
      </Button>
    </>
  )
}

export default CompleteFields
