import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Row, Col, Form, Button, Modal, Collapse, Card } from "react-bootstrap"
import firebase from "gatsby-plugin-firebase"
import CompleteFields from "../components/complete-fields"

const Commitments = ({ fbUser, uid, location }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [contextButtons, setContextButtons] = useState({})
  const [cfetEmail, setCFETEmail] = useState(false)
  const formRef = useRef(null)

  const [modalContent, setModalContent] = useState({
    modalTitle: "",
    modalBody: "",
    modalFunction: "",
  })

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const responses = {
    "no-response": "Not started",
    "not-started": "Planned start date",
    "3-months": "Intend to complete in 3 months",
    "6-months": "Intend to complete in 6 months",
    completed: "Completed",
  }

  // const responses = ['No Response', 'Not Started', '3 months', '6 months', '9 months', 'Completed'];
  const [commitments, setCommitments] = useState([])

  const data = useStaticQuery(graphql`
    query {
      allContentfulCallForEquityPage {
        edges {
          node {
            commitments {
              commitment {
                childMarkdownRemark {
                  html
                }
              }
              additionalContext {
                childMarkdownRemark {
                  html
                }
              }
              otherField
            }
            timingOrCompletionCopy
          }
        }
      }
    }
  `)

  const commitmentData =
    data.allContentfulCallForEquityPage.edges[0].node.commitments
  const companyId = fbUser.company
  const [orgResponses, setOrgResponses] = useState({})

  //get company status
  const orgRef = firebase.database().ref("/orgs/" + companyId)

  useEffect(() => {
    setCommitments(commitments)
    //set organization responses
    orgRef.on("value", snapshot => {
      const val = snapshot.val()
      setOrgResponses(val)
    })
  }, [])

  const handleRevokeModal = e => {
    setModalContent({
      modalTitle: "Are you sure?",
      modalBody: `By clicking "Revoke Access" below, you will no longer have access to edit ${orgResponses.companyName}'s commitments.
      You will need to register again.`,
      modalFunction: "revoke",
    })

    handleShow()
  }
  const handleUpdateOrg = e => {
    e.preventDefault()
    orgRef.set(orgResponses)
    setModalContent({
      modalTitle: "Updates Saved!",
      modalBody: `Thank you. You've updated ${orgResponses.companyName}'s commitments.
      You can leave this page or continue editing.`,
      modalFunction: "default",
    })

    handleShow()
    // firebase.database().ref("/orgs/" + fbUser.companySlug).set( fbOrg );
  }

  const handleCompanyInputchange = e => {
    const { name, value } = e.target
    const d = new Date()
    const timestamp = d.getTime()

    // console.log(name, value)

    setOrgResponses(prevState => ({
      ...prevState,
      [name]: value,
      timestamp: timestamp,
    }))
    // orgRef.child(name).set(value);
  }

  const toggleContext = index => {
    // console.log(index)
    const num = index

    setContextButtons(prevState => {
      return {
        ...prevState,
        [num]: !contextButtons[num],
      }
    })
  }

  //Send email to new CFEPT contact
  const handleEmailInputChange = e => {
    const { value } = e.target
    setCFETEmail(value)
  }

  const handleSendEmail = e => {
    e.preventDefault()

    const dataToUser = {
      fromName: "POCAM Admin",
      fromEmail: "noreply@wearepocam.ca",
      toEmail: cfetEmail,
      subject: "The Call for Equity Progress Tracker Access",
      message: `
        Hello,

        ${fbUser.name} from ${
        orgResponses.companyName
      } has informed POCAM that you will be taking over access for The Call for Equity Progress Tracker.

        If you haven't signed up yet, please do so at:
        
        ${location.origin + "/update-status"}

        Thank you
      `,
    }

    firebase
      .database()
      .ref("/users/" + uid)
      .remove()

    if (validateEmail(cfetEmail)) {
      sendEmail(dataToUser)
    }
  }

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const sendEmail = async (data, e) => {
    try {
      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        //not 200 response
        return
      }
      //all OK
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Success`);
      // console.log("email sent")
    } catch (e) {
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Fail`);
      //error
      console.log(e)
    }
  }

  useEffect(() => {
    // console.log(cfetEmail)
  }, [cfetEmail])

  useEffect(() => {
    const count = orgResponses.agencyType === "agency" ? 12 : 15
    const commitments = commitmentData.slice(0, count)
    setCommitments(commitments)

    let contextButtonObj = {}

    commitments.forEach((commitment, idx) => {
      if (commitment.additionalContext) {
        contextButtonObj[idx] = false
      }
    })
    setContextButtons(contextButtonObj)
  }, [orgResponses])

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.modalTitle}</Modal.Title>
        </Modal.Header>

        {modalContent.modalFunction === "revoke" ? (
          <Form onSubmit={handleSendEmail}>
            <Modal.Body>
              <p>{modalContent.modalBody}</p>

              <p>{`Optional: If you know the email of ${orgResponses.companyName}'s new Call for Equity contact, please enter it below, and we will remind them to register.`}</p>

              <Form.Control
                type="email"
                placeholder="name@organization.com"
                onChange={handleEmailInputChange}
              ></Form.Control>
              <p className="text-secondary mb-1">
                <small>We will only send them one notice.</small>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" type="submit">
                Please remove me
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <>
            <Modal.Body>
              <p>{modalContent.modalBody}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button as={Link} variant="secondary" to="/call-for-equity/">
                View Call for Equity
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Continue Editing
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      
      <Row>
        <Col>
          <h1 className="display-4 mb-2">Call for Equity Progress Tracker</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="8">
          
          <h2>Manage your organization's commitment progress</h2>
        </Col>
      </Row>

      <Row>
        <Col md="4">
        
          <div className="sticky-top mb-4">
          <Card>
              <Card.Body>
            <h3 className="mb-3">Hi {fbUser.name},</h3>
            <p className="mb-4">
              As the Call for Equity contact, use this tracker to record your
              organization's progress on the {commitments.length} POCAM Call for
              Equity commitments.
            </p>
            

              
            <p className="mb-1">You are sharing the progress made by:</p>
            <p className="h4 mb-4">
              {orgResponses.companyName || "undefined"}{" "}
            </p>
            <Form className="mb-4">
              <Form.Group>
                <Form.Label>Organization type:</Form.Label>

                <Form.Check
                  custom
                  name="agencyType"
                  value="client"
                  type="radio"
                  label="Client"
                  id="client"
                  onChange={handleCompanyInputchange}
                  checked={orgResponses["agencyType"] === "client"}
                />

                <Form.Check
                  custom
                  name="agencyType"
                  value="agency"
                  type="radio"
                  label="Agency/Other Industry Org"
                  // checked="true"
                  id="agency"
                  onChange={handleCompanyInputchange}
                  checked={orgResponses["agencyType"] === "agency"}
                />
              </Form.Group>
            </Form>
            <p className="mb-2">
              If you no longer represent{" "}
              <strong>{orgResponses.companyName}</strong> please:
            </p>
            <Button className="mb-4" onClick={handleRevokeModal}>
              Remove Your Access
            </Button>
            </Card.Body>
            </Card>
          </div>
        </Col>
        <Col md="8">
          <Form onSubmit={handleUpdateOrg}>
            {commitments.map((commitment, index) => {
              // console.log(fbOrg);
              const indexTwoDigit = ("0" + (index + 1)).slice(-2)
              const commitmentName = `commitment-${indexTwoDigit}`

              return (
                <div key={`c-${index}`}>
                  <Form.Group controlId="formName" className="mb-5">
                    <Form.Label>
                      <p className="mb-1">Commitment {index + 1}</p>
                      <div
                        className="commitment-content"
                        dangerouslySetInnerHTML={{
                          __html:
                            commitment.commitment.childMarkdownRemark.html,
                        }}
                      />
                      {commitment.additionalContext ? (
                        <>
                          <Collapse in={contextButtons[index]}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  commitment.additionalContext
                                    .childMarkdownRemark.html,
                              }}
                            />
                          </Collapse>

                          <Button
                            variant="secondary"
                            className="mb-2"
                            onClick={() => {
                              toggleContext(index)
                            }}
                            size="sm"
                          >
                            {contextButtons[index] ? "Hide" : "Read"} Additional
                            Context
                          </Button>
                        </>
                      ) : null}
                    </Form.Label>
                    {Object.keys(responses).map((key, idx) => {
                      const value = responses[key]
                      const slug = key

                      const id = `cb-${indexTwoDigit}-${idx}`

                      return (
                        <div key={`${index}-${idx}`}>
                          <Form.Check
                            custom
                            name={commitmentName}
                            value={slug}
                            type="radio"
                            label={value}
                            id={id}
                            onChange={handleCompanyInputchange}
                            checked={orgResponses[commitmentName] === slug}
                          />
                          {idx === 1 &&
                          orgResponses[commitmentName] === "not-started" ? (
                            <Row className="w-50 mt-1 ml-2">
                              <Col className="pr-0">
                                <Form.Control
                                  name={`${commitmentName}-startMonth`}
                                  onChange={handleCompanyInputchange}
                                  disabled={
                                    orgResponses[commitmentName] !==
                                    "not-started"
                                  }
                                  as="select"
                                  custom
                                  defaultValue={
                                    orgResponses[
                                      `${commitmentName}-startMonth`
                                    ] || 1
                                  }
                                  required
                                >
                                  {months.map(month => (
                                    <option key={month}> {month}</option>
                                  ))}
                                </Form.Control>
                                <p>
                                  <small>Month</small>
                                </p>
                              </Col>
                              <Col>
                                <Form.Control
                                  name={`${commitmentName}-startYear`}
                                  onChange={handleCompanyInputchange}
                                  disabled={
                                    orgResponses[commitmentName] !==
                                    "not-started"
                                  }
                                  as="select"
                                  custom
                                  defaultValue={
                                    orgResponses[
                                      `${commitmentName}-startYear`
                                    ] || 2022
                                  }
                                  required
                                >
                                  <option>2021</option>
                                  <option>2022</option>
                                  <option>2023</option>
                                </Form.Control>
                                <p>
                                  <small>Year</small>
                                </p>
                              </Col>
                            </Row>
                          ) : null}
                          {commitment.otherField &&
                          idx === 4 &&
                          orgResponses[commitmentName] === "completed" ? (
                            <CompleteFields
                              commitmentName={commitmentName}
                              completed={orgResponses[commitmentName]}
                              orgResponses={orgResponses}
                              setOrgResponses={setOrgResponses}
                              handleChange={handleCompanyInputchange}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    })}
                  </Form.Group>
                </div>
              )
            })}
            <Row className="sticky-bottom py-3 py-lg-4">
              <Col>
                <Button type="submit">
                  Update progress for {orgResponses.companyName || "undefined"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default Commitments
