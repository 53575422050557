import React, { useContext, useState, useEffect } from "react"
import { AuthContext } from "../context/auth"
import { Row, Col, Form, Modal, Card, Button } from "react-bootstrap"
import firebase from "gatsby-plugin-firebase"

const Login = ({ location, page, children }) => {
  const { user } = useContext(AuthContext)

  const origin = location.origin ? location.origin : ""

  // const [ user, setUser ] = useState(null);
  const [email, setEmail] = useState("")

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [modalContent, setModalContent] = useState({
    title: "Default Title",
    content: "Default Content",
  })

  //form state
  const [formSuccess, setFormSuccess] = useState(false)
  const [formContent, setFormContent] = useState({
    title: "Sign in",
    content: "Enter your work email address for a login link.",
  })

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: `${origin}/${page}`,
    // This must be true.
    handleCodeInApp: true,
  }

  //input handler
  const handleInputChange = e => {
    const { name, value } = e.target
    setEmail(value)
  }

  //handle firebase sign in/out
  const handleFormSubmit = e => {
    e.preventDefault()

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function () {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email)
        setFormSuccess(true)
        setFormContent({
          title: `Login link sent!`,
          content: `You'll receive an email from POCAM with an authorized login link. You can now close this tab.`,
        })
        // handleShow();
      })
      .catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        console.log(error)
      })
  }

  useEffect(() => {
    let preEmail = window.localStorage.getItem("emailForSignIn")

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let signInEmail = window.localStorage.getItem("emailForSignIn")

      if (!signInEmail) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        signInEmail = window.prompt(
          "Please provide your email for confirmation"
        )
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(signInEmail, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn")
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          console.log(result)
          // setUser(result);
          // setUserSignedIn(true);
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
    }
  }, [])

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent.content}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {user ? (
        children(user, handleShow, setModalContent)
      ) : (
        <>
          <Row className="justify-content-center">
            <Col md="6">
              <Card>
                <Card.Body>
                  <h2>{formContent.title}</h2>
                  <p className="lead">{formContent.content}</p>
                  {!formSuccess ? (
                    <Form onSubmit={handleFormSubmit}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="name@workdomain.com"
                          onChange={handleInputChange}
                        />
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Button type="submit" variant="primary">
                        Submit
                      </Button>
                    </Form>
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default Login
