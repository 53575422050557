import React from "react"
import Layout from "../components/layout"
import Login from "../components/login"
import { Container } from "react-bootstrap"
import EmailVerified from "../components/email-verified"

const RegisterEmailPage = ({ location, data }) => {
  // console.log(location);
  //check if admin
  /*
    1. check if user is an admin
    2. open list of users, show email, and linkedin url
    3. enable or disable access use toggle

  */
  return (
    <Layout>
      <section className="py-4 py-lg-5">
        <Container>
          <Login location={location} page={"update-status"}>
            {(user, handleShow, setModalContent) => {
              console.log(user)
              return (
                <EmailVerified
                  location={location}
                  handleShow={handleShow}
                  setModalContent={setModalContent}
                  user={user}
                />
              )
            }}
          </Login>
        </Container>
      </section>
    </Layout>
  )
}

export default RegisterEmailPage
